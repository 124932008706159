if (typeof (window as any).global === 'undefined') {
	(window as any).global = window;
}
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import 'element-plus/theme-chalk/index.css';
import VueCookies from 'vue-cookies';
import wx from 'weixin-js-sdk';
import 'vant/es/notify/style';
import 'vant/es/toast/style';
import 'vant/lib/index.css';
import { FloatingBubble } from 'vant';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

// import Vue3DraggableResizable from 'vue3-draggable-resizable';
// import Vconsole from 'vconsole';

const app = createApp(App);

if (process.env.NODE_ENV !== 'production') {
	// let vConsole: any = new Vconsole();
	// app.use(vConsole);
}

// 接入Sentry
// if (process.env.NODE_ENV === 'production') {
// 	Sentry.init({
// 		app,
// 		dsn: 'https://1cc80414d91fecd5185b3fd6d14daeb5@sentry.ouc-online.com.cn/2',
// 		integrations: [
// 			new Integrations.BrowserTracing({
// 				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// 				tracingOrigins: ['web.le.ouc-online.com.cn/', 'le.ouchn.cn/', /^\//],
// 			}),
// 		],
// 		// Set tracesSampleRate to 1.0 to capture 100%
// 		// of transactions for performance monitoring.
// 		// We recommend adjusting this value in production
// 		tracesSampleRate: 1.0,
// 	});
// }

app.config.globalProperties.$cookies = VueCookies;

app.directive('focus', {
	mounted(el) {
		el.focus();
	},
});

// app.use(Vue3DraggableResizable);
app.use(FloatingBubble);
app.use(router);
app.use(store);
app.use(wx);
app.mount('#app');
