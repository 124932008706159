import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import pcRouter from './pcRouter'; //引入路由
import mobileRouter from './mobileRouter'; //引入移动端路由
import VueCookies from 'vue-cookies';
import { mgr } from '@/assets/ts/oidcConfig';
import { store } from '../store';
import { handleMutalLoginMsg, handleTokenInfo, isOurMiniProgram } from '@/utils/common';
import { getUserToken } from '@/http/api/basedata';

// 判断移动端还是pc端
const flagRouter = JSON.parse(sessionStorage.getItem('flag') || '0');
// 公共路由，登录加载
const commonRouter = {
	path: '/loginLoading',
	name: 'LoginLoading',
	component: () => import('@/views/pc/loginLoading/index.vue'),
};
const router = createRouter({
	history: createWebHistory(),
	routes: flagRouter ? [...mobileRouter, commonRouter] : [...pcRouter, commonRouter], //路由
	// routes: [...pcRouter]//路由
});

router.beforeEach(async (to, from, next) => {
	// 10月7日根据活动id重定向活动页面
	if (window.location.href.indexOf('Event/5f20fe2e-582d-1c77-cb5f-1cfbecb2b7da') >= 0) {
		location.href =
			'http://looseleaf-v2.ysy.crtvup.com.cn/previewAdapter?qrId=1710474676541689856&businessTitle=&pageType=h5&styleType=B';
		return;
	}
	// 从别的网站跳过来，参数islogin为true,再登录一次
	if (to.query.islogin) {
		const url = window.location.origin + window.location.pathname;
		var scheme = to.query.scheme;
		if (scheme) {
			const extraQueryParams = { scheme };
			mgr.signinRedirect({ state: url, extraQueryParams });
		}
		mgr.signinRedirect({ state: url });
	}

	// 从别的网站跳过来，参数mutual为true,修改路由
	if (to.query.mutual) {
		router.push({ path: window.location.pathname });
		handleMutalLoginMsg();
	}
	const loginStatus = VueCookies.get('loginStatus');
	// 如果token为空，强制跳转到登录页面；否则，直接放行
	if (!flagRouter && !loginStatus && to.path === '/mine') {
		alert('请登录');
		return next('/home');
	}

	if (!store.state.baseDataStatus) {
		store.dispatch('getBaseData');
	}

	if (!store.state.passportStatus && loginStatus) {
		store.dispatch('getPassportUserInfo');
	}

	// 如果是手机端小程序内嵌webview而且当前未登录  isOurMiniProgram()
	if (flagRouter && !loginStatus && isOurMiniProgram()) {
		store.state.JtiStatus = to.query.JtiId ? true : false;
		// ElMessage.error('JtiId是' + to.query.JtiId);
		// 如果小程序已经登录获取携带过来的JtiId
		if (to.query.JtiId) {
			const Jti = to.query.JtiId;
			// 根据Jti获取用户Token信息
			const access_token = await getUserToken({ Jti });
			handleTokenInfo(access_token);
			// 处理后替换当前路由
			router.push({ path: window.location.pathname });
		}
	}

	document.title = to.meta.title || '终身教育平台';
	// chrome
	document.body.scrollTop = 0;
	// firefox
	document.documentElement.scrollTop = 0;
	// safari
	window.pageYOffset = 0;
	next();
});

export default router;
