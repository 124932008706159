import Home from '@/views/pc/home/index.vue';
import EmptyParent from '@/components/EmptyParent.vue';
const pcRouter = [
	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			title: '首页 | 终身教育平台',
		},
	},

	{
		path: '/interestCourseList',
		name: 'InterestCourseList',
		component: () => import('@/views/pc/interestCourseList/index.vue'),
		meta: {
			title: '兴趣 | 终身教育平台',
		},
	},
	{
		path: '/lifeCourseList',
		name: 'LifeCourseList',
		component: () => import('@/views/pc/lifeCourseList/index.vue'),
		meta: {
			title: '生活 | 终身教育平台',
		},
	},
	{
		path: '/workCourseList',
		name: 'WorkCourseList',
		component: () => import('@/views/pc/workCourseList/index.vue'),
		meta: {
			title: '职场 | 终身教育平台',
		},
	},
	{
		path: '/skillCourseList',
		name: 'SkillCourseList',
		component: () => import('@/views/pc/skillCourseList/index.vue'),
		meta: {
			title: '技能 | 终身教育平台',
		},
	},
	{
		path: '/educationCourseList',
		name: 'EducationCourseList',
		component: () => import('@/views/pc/educationCourseList/index.vue'),
		meta: {
			title: '学历 | 终身教育平台',
		},
	},
	{
		path: '/agedCourseList',
		name: 'AgedCourseList',
		component: () => import('@/views/pc/agedCourseList/index.vue'),
		meta: {
			title: '老年 | 终身教育平台',
		},
	},
	{
		path: '/libraryCourseList',
		name: 'LibraryCourseList',
		component: () => import('@/views/pc/libraryCourseList/index.vue'),
		meta: {
			title: '图书馆 | 终身教育平台',
		},
	},

	{
		path: '/noticeInfo',
		name: 'NoticeInfo',
		component: () => import('@/views/pc/noticeInfo/index.vue'),
		meta: {
			title: '通知公告 | 终身教育平台',
		},
	},

	// {
	//     path: '/courseDetails/:CourseId/:LastModuleId?',
	//     name: 'CourseDetails',
	//     component: () => import('@/views/pc/courseDetails/index.vue')
	// },
	{
		path: '/courseDetails/:CourseId/:LastModuleCode?/:BatchId?',
		name: 'CourseDetails',
		component: () => import('@/views/pc/courseDetails/index.vue'),
	},
	{
		path: '/educationCourseDetails/:CourseId/:BatchId?',
		name: 'EducationCourseDetails',
		component: () => import('@/views/pc/courseDetails/education.vue'),
	},
	{
		path: '/externalCourseDetails/:CourseId',
		name: 'ExternalCourseDetails',
		component: () => import('@/views/pc/courseDetails/external.vue'),
	},
	{
		path: '/submitOrder',
		name: 'SubmitOrder',
		component: () => import('@/views/pc/submitOrder/index.vue'),
	},
	{
		path: '/schoolList',
		name: 'SchoolList',
		component: () => import('@/views/pc/schoolList/index.vue'),
	},
	{
		path: '/schoolCourseList',
		name: 'SchoolCourseList',
		component: () => import('@/views/pc/schoolCourseList/index.vue'),
	},
	{
		path: '/searchCourseList/:TypeId?/:Keyword?',
		name: 'SearchCourseList',
		component: () => import('@/views/pc/searchCourseList/index.vue'),
	},
	{
		path: '/screenCourseList', // /频道id/一级分类id/二级分类id/搜索关键词
		name: 'ScreenCourseList',
		component: () => import('@/views/pc/screenCourseList/index.vue'),
	},
	{
		path: '/screenCourseList/:ChannelId?/:ParentId?', // /频道id/一级分类id
		name: 'ScreenCourseList',
		component: () => import('@/views/pc/screenCourseList/index.vue'),
	},
	{
		path: '/specialCourseList',
		name: 'SpecialCourseList',
		component: () => import('@/views/pc/specialCourseList/index.vue'),
	},
	{
		path: '/tagCourseList',
		name: 'TagCourseList',
		component: () => import('@/views/pc/tagCourseList/index.vue'),
	},
	{
		path: '/mine',
		name: 'Mine',
		component: () => import('@/views/pc/mine/index.vue'),
	},
	{
		path: '/mine/:MineId?/:TabId?',
		name: 'Mine',
		component: () => import('@/views/pc/mine/index.vue'),
	},
	// 订单详情
	{
		path: '/myOrderDetails',
		name: 'MyOrderDetails',
		component: () => import('@/views/pc/mine/components/myOrderDetails.vue'),
	},
	// 退款详情
	{
		path: '/myOrderRefund',
		name: 'MyOrderRefund',
		component: () => import('@/views/pc/mine/components/myOrderRefund.vue'),
	},
	// 付费协议
	{
		path: '/paymentAgreement',
		name: 'PaymentAgreement',
		component: () => import('@/views/pc/mine/components/paymentAgreement.vue'),
	},

	// 付费协议
	{
		path: '/privacyPolicy',
		name: 'PrivacyPolicy',
		component: () => import('@/views/pc/mine/components/privacyPolicy.vue'),
	},

	// 活动
	{
		path: '/event/:EventId?',
		name: 'Event',
		component: () => import('@/views/pc/event/index.vue'),
	},

	// 机构
	{
		path: '/organization/:Id?',
		name: 'Organization',
		component: () => import('@/views/pc/organization/index.vue'),
	},

	// 下载
	{
		path: '/download',
		name: 'Download',
		component: () => import('@/views/pc/download/index.vue'),
	},
	{
		path: '/event/:EventId',
		name: 'Event',
		component: () => import('@/views/pc/event/index.vue'),
	},
	{
		path: '/download',
		name: 'Download',
		component: () => import('@/views/pc/download/index.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/pc/login/index.vue'),
	},
	{
		path: '/login2',
		name: 'Login2',
		component: () => import('@/views/pc/login/home.vue'),
	},
	{
		path: '/signin-oidc',
		name: 'SigninOidc',
		component: () => import('@/views/pc/login/signin-oidc.vue'),
	},
	{
		path: '/video',
		name: 'Video',
		component: () => import('@/views/pc/video/index.vue'),
	},

	{
		path: '/specialBookList',
		name: 'SpecialBookList',
		component: () => import('@/views/pc/specialBookList/index.vue'),
	},
	{
		path: '/bookDetail/:id?',
		name: 'BookDetail',
		component: () => import('@/views/pc/bookDetail/index.vue'),
	},
	{
		path: '/LiveCourseDetails/:CourseId',
		name: 'LiveCourseDetails',
		component: () => import('@/views/pc/courseDetails/live.vue'),
	},

	{
		path: '/applyInvoice',
		name: 'ApplyInvoice',
		component: () => import('@/views/pc/mine/invoice/ApplyInvoice.vue'),
	},

	{
		path: '/applyInvoiceResult',
		name: 'ApplyInvoiceResult',
		component: () => import('@/views/pc/mine/invoice/ApplyInvoiceResult.vue'),
	},

	{
		path: '/invoiceAgreement',
		name: 'Agreement',
		component: () => import('@/views/pc/mine/invoice/agreement.vue'),
	},

	{
		path: '/serviceAgreement',
		name: 'ServiceAgreement',
		component: () => import('@/views/pc/mine/components/agreement.vue'),
	},

	{
		path: '/noticeDetail',
		name: 'NoticeDetail',
		component: () => import('@/views/pc/noticeDetail/index.vue'),
	},

	{
		path: '/rankCourseList',
		name: 'RankCourseList',
		component: () => import('@/views/pc/rankCourseList/index.vue'),
	},

	{
		path: '/digitalHuman',
		name: 'digitalHuman',
		component: () => import('@/views/pc/digitalHuman/index.vue'),
	},

	{
		path: '/getCertificate',
		name: 'getCertificate',
		component: () => import('@/views/pc/getCertificate/index.vue'),
	},

	{
		path: '/unique',
		name: 'Unique',
		component: EmptyParent,
		children: [
			{
				path: 'deptOfRes/home',
				name: 'DeptOfResHome',
				component: () => import('@/views/pc/unique/deptOfRes/Home.vue'),
			},
			{
				path: 'deptOfRes/classifyCourse',
				name: 'DeptOfResClassifyCourse',
				component: () => import('@/views/pc/unique/deptOfRes/ClassifyCourse.vue'),
			},
			{
				path: 'deptOfRes/courseDetails/:CourseId/:LastModuleCode?/:BatchId?',
				name: 'DeptOfResMicroCourseDetails',
				component: () => import('@/views/pc/unique/deptOfRes/courseDetails/index.vue'),
			},
			{
				path: 'deptOfRes/searchCourseList',
				name: 'DeptOfResSearchCourseList',
				component: () => import('@/views/pc/unique/deptOfRes/searchCourseList/index.vue'),
			},
		],
	},
];
export default pcRouter;
