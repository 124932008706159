import Home from '@/views/mobile/home/index.vue';
const mobileRouter = [
	{
		path: '/',
		redirect: '/home',
	},
	// 首页
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			title: '首页 | 终身教育平台',
		},
	},

	// 兴趣
	{
		path: '/interestCourseList',
		name: 'InterestCourseList',
		component: () => import('@/views/mobile/interestCourseList/index.vue'),
		meta: {
			title: '兴趣 | 终身教育平台',
		},
	},
	// 生活
	{
		path: '/lifeCourseList',
		name: 'LifeCourseList',
		component: () => import('@/views/mobile/lifeCourseList/index.vue'),
		meta: {
			title: '生活 | 终身教育平台',
		},
	},
	// 职业
	{
		path: '/workCourseList',
		name: 'WorkCourseList',
		component: () => import('@/views/mobile/workCourseList/index.vue'),
		meta: {
			title: '职场 | 终身教育平台',
		},
	},
	// 学历
	{
		path: '/educationCourseList',
		name: 'EducationCourseList',
		component: () => import('@/views/mobile/educationCourseList/index.vue'),
		meta: {
			title: '学历 | 终身教育平台',
		},
	},
	// 老年
	{
		path: '/agedCourseList',
		name: 'AgedCourseList',
		component: () => import('@/views/mobile/agedCourseList/index.vue'),
		meta: {
			title: '老年 | 终身教育平台',
		},
	},
	// 技能
	{
		path: '/skillCourseList',
		name: 'SkillCourseList',
		component: () => import('@/views/mobile/skillCourseList/index.vue'),
		meta: {
			title: '技能 | 终身教育平台',
		},
	},
	{
		path: '/libraryCourseList',
		name: 'LibraryCourseList',
		component: () => import('@/views/mobile/libraryCourseList/index.vue'),
		meta: {
			title: '图书馆 | 终身教育平台',
		},
	},
	// 专题列表页
	{
		path: '/specialCourseList',
		name: 'SpecialCourseList',
		component: () => import('@/views/mobile/specialCourseList/index.vue'),
	},
	// 标签列表页
	{
		path: '/tagCourseList',
		name: 'TagCourseList',
		component: () => import('@/views/mobile/tagCourseList/index.vue'),
	},
	// 资源库列表页
	{
		path: '/schoolCourseList',
		name: 'SchoolCourseList',
		component: () => import('@/views/mobile/schoolCourseList/index.vue'),
	},
	// 课程详情 - 微课
	// {
	//     path: '/courseDetails/:CourseId/:LastModuleId?',
	//     name: 'CourseDetails',
	//     component: () => import('@/views/mobile/courseDetails/index.vue')
	// },
	{
		path: '/courseDetails/:CourseId/:LastModuleCode?/:BatchId?',
		name: 'CourseDetails',
		component: () => import('@/views/mobile/courseDetails/index.vue'),
	},
	// 课程详情 - 学历
	{
		path: '/educationCourseDetails/:CourseId/:BatchId?',
		name: 'EducationCourseDetails',
		component: () => import('@/views/mobile/courseDetails/education.vue'),
	},
	// 课程详情 - 外部
	{
		path: '/externalCourseDetails/:CourseId',
		name: 'ExternalCourseDetails',
		component: () => import('@/views/mobile/courseDetails/external.vue'),
	},

	// 提交订单
	{
		path: '/submitOrder',
		name: 'SubmitOrder',
		component: () => import('@/views/mobile/submitOrder/index.vue'),
	},
	// 评论详情
	{
		path: '/commentDetails',
		name: 'CommentDetails',
		component: () => import('@/views/mobile/commentDetails/index.vue'),
	},
	// 分类
	// {
	// 	path: '/sort/:ChannelId?/:ParentId?',
	// 	name: 'Sort',
	// 	component: () => import('@/views/mobile/sort/index.vue'),
	// },
	{
		path: '/screenCourseList', // 频道id/一级分类id/二级分类id/搜索关键词/来源id/资源库id
		name: 'ScreenCourseList',
		component: () => import('@/views/mobile/screenCourseList/index.vue'),
	},
	// 我的
	{
		path: '/mine',
		name: 'Mine',
		component: () => import('@/views/mobile/mine/index.vue'),
	},

	// 我的图书
	{
		path: '/myLibrary',
		name: 'MyLibrary',
		component: () => import('@/views/mobile/myLibrary/index.vue'),
	},

	// 我的课程
	{
		path: '/myCourse/:TabId?',
		name: 'MyCourse',
		component: () => import('@/views/mobile/myCourse/index.vue'),
	},
	// 我的笔记
	{
		path: '/myNote',
		name: 'MyNote',
		component: () => import('@/views/mobile/myNote/index.vue'),
	},
	// 我的收藏
	{
		path: '/myCollection',
		name: 'MyCollection',
		component: () => import('@/views/mobile/myCollection/index.vue'),
	},
	// 我的喜欢
	{
		path: '/myLike',
		name: 'MyLike',
		component: () => import('@/views/mobile/myLike/index.vue'),
	},
	// 我的好友
	{
		path: '/myFriend',
		name: 'MyFriend',
		component: () => import('@/views/mobile/myFriend/index.vue'),
	},
	// 新的朋友
	{
		path: '/myNewFriend',
		name: 'MyNewFriend',
		component: () => import('@/views/mobile/myNewFriend/index.vue'),
	},
	// 朋友个人主页
	{
		path: '/friendHomepage',
		name: 'FriendHomepage',
		component: () => import('@/views/mobile/friendHomepage/index.vue'),
	},
	// 我的反馈
	{
		path: '/myFeedback',
		name: 'MyFeedback',
		component: () => import('@/views/mobile/myFeedback/index.vue'),
	},
	// 我的关注
	{
		path: '/myFollow',
		name: 'MyFollow',
		component: () => import('@/views/mobile/myFollow/index.vue'),
	},
	// 我的订单
	{
		path: '/myOrder',
		name: 'MyOrder',
		component: () => import('@/views/mobile/myOrder/index.vue'),
	},
	// 我的订单详情
	{
		path: '/myOrderDetails',
		name: 'MyOrderDetails',
		component: () => import('@/views/mobile/myOrderDetails/index.vue'),
	},
	// 我的订单 - 申请退款
	{
		path: '/myOrderRefund',
		name: 'MyOrderRefund',
		component: () => import('@/views/mobile/myOrderRefund/index.vue'),
	},
	// 付费协议
	{
		path: '/paymentAgreement',
		name: 'PaymentAgreement',
		component: () => import('@/views/mobile/paymentAgreement/index.vue'),
	},
	// 我的设置
	{
		path: '/mySet',
		name: 'MySet',
		component: () => import('@/views/mobile/mySet/index.vue'),
	},
	// 个人资料
	{
		path: '/myPersonalData',
		name: 'MyPersonalData',
		component: () => import('@/views/mobile/myPersonalData/index.vue'),
	},
	// 隐私设置
	{
		path: '/myPrivacySet',
		name: 'MyPrivacySet',
		component: () => import('@/views/mobile/myPrivacySet/index.vue'),
	},
	// 申请添加好友
	{
		path: '/applyAddFriend',
		name: 'ApplyAddFriend',
		component: () => import('@/views/mobile/applyAddFriend/index.vue'),
	},
	//下载app
	// 活动
	{
		path: '/event/:EventId?',
		name: 'Event',
		component: () => import('@/views/mobile/event/index.vue'),
	},

	// 机构
	{
		path: '/organization/:Id?',
		name: 'Organization',
		component: () => import('@/views/mobile/organization/index.vue'),
	},

	// 下载
	{
		path: '/download',
		name: 'Download',
		component: () => import('@/views/mobile/download/index.vue'),
	},
	// 用户协议
	{
		path: '/agreement',
		name: 'Agreement',
		component: () => import('@/views/mobile/agreement/index.vue'),
	},
	// 隐私设置
	// 隐私政策
	{
		path: '/privacyPolicy',
		name: 'PrivacyPolicy',
		component: () => import('@/views/mobile/privacyPolicy/index.vue'),
	},
	// 登录
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/mobile/login/index.vue'),
	},
	// signin-oidc
	{
		path: '/signin-oidc',
		name: 'SigninOidc',
		component: () => import('@/views/mobile/login/signin-oidc.vue'),
	},
	// {
	//     path: '/test',
	//     name: 'Test',
	//     component: () => import('@/views/mobile/test/index.vue')
	// },
	{
		path: '/video',
		name: 'Video',
		component: () => import('@/views/pc/video/index.vue'),
	},

	// 图书专题列表页
	{
		path: '/specialBookList',
		name: 'SpecialBookList',
		component: () => import('@/views/mobile/specialBookList/index.vue'),
	},

	// 我的证书
	{
		path: '/myCertificates/:TabId?',
		name: 'MyCertificates',
		component: () => import('@/views/mobile/myCertificates/index.vue'),
	},

	{
		path: '/liveCourseDetails/:CourseId',
		name: 'LiveCourseDetails',
		component: () => import('@/views/mobile/courseDetails/live.vue'),
	},

	{
		path: '/applyInvoice',
		name: 'ApplyInvoice',
		component: () => import('@/views/mobile/mine/invoice/ApplyInvoice.vue'),
	},

	{
		path: '/applyInvoiceResult',
		name: 'ApplyInvoiceResult',
		component: () => import('@/views/mobile/mine/invoice/ApplyInvoiceResult.vue'),
	},

	{
		path: '/invoiceAgreement',
		name: 'Agreement',
		component: () => import('@/views/mobile/mine/invoice/agreement.vue'),
	},

	{
		path: '/serviceAgreement',
		name: 'ServiceAgreement',
		component: () => import('@/views/mobile/agreement/index.vue'),
	},

	{
		path: '/noticeDetail',
		name: 'NoticeDetail',
		component: () => import('@/views/mobile/noticeDetail/index.vue'),
	},
	{
		path: '/noticeInfo',
		name: 'NoticeInfo',
		component: () => import('@/views/mobile/noticeInfo/index.vue'),
	},
	{
		path: '/bankBrief',
		name: 'BankBrief',
		component: () => import('@/views/mobile/myStudyRecord/BankBrief.vue'),
	},
	{
		path: '/recordDetail',
		name: 'RecordDetail',
		component: () => import('@/views/mobile/myStudyRecord/RecordDetail.vue'),
	},
	{
		path: '/studyUserInfo',
		name: 'StudyUserInfo',
		component: () => import('@/views/mobile/myStudyRecord/StudyUserInfo.vue'),
	},
	{
		path: '/myNews',
		name: 'MyNews',
		component: () => import('@/views/mobile/myNews/index.vue'),
	},
	{
		path: '/rankCourseList',
		name: 'RankCourseList',
		component: () => import('@/views/mobile/rankCourseList/index.vue'),
	},
	{
		path: '/courseDiscuss',
		name: 'CourseDiscuss',
		component: () => import('@/views/mobile/courseDiscuss/index.vue'),
	},
	{
		path: '/writeDiscuss',
		name: 'WriteDiscuss',
		component: () => import('@/views/mobile/courseDiscuss/WriteDiscuss.vue'),
	},
	{
		path: '/baizeH5',
		name: 'BaizeH5',
		component: () => import('@/views/mobile/baizeH5/index.vue'),
	},
	{
		path: '/getCertificate',
		name: 'getCertificate',
		component: () => import('@/views/mobile/getCertificate/index.vue'),
	},
];

export default mobileRouter;
